import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from './login.service';

import * as moment from 'moment';
import { SnotifyService } from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class Authgard implements CanActivate {

  constructor(private router: Router,
              private snotifyService: SnotifyService,
              private loginService: LoginService) {
  }

  canActivate() {
    const token = this.getToken();
    const expiration = atob(token).split(':');
    const isTokenExpire = moment(moment(expiration[2], 'x')).isBefore(new Date());

    if (isTokenExpire) {// logout
      this.logout();
      return false;
    } else { // extend token life
      return true;
    }
  }


  public getToken(): string {
    return sessionStorage.getItem('JWAUTOTOKEN');
  }

  setToken(login: string, pwd: string) {
    const expiration = moment(new Date()).add(30, 'minutes');
    sessionStorage.setItem('JWAUTOTOKEN', btoa(login + ':' + pwd + ':' + expiration));
  }


  logout() {
    this.loginService.login = undefined;
    this.loginService.user = undefined;
    this.loginService.sendEvent();
    this.snotifyService.info('Votre session a expiré.', 'Déconnexion', {
      timeout: 3500,
      titleMaxLength: 30,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true
    });
  }

}
