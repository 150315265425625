import { Component, OnInit } from '@angular/core';
import { RapportBusinessService } from 'src/app/shared/services/rapport-business.service';
import { UtilService } from 'src/app/shared/services/utils-services/util.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  rapportsToTreat: any = 0;

  constructor(private rapportBusinessService: RapportBusinessService,
    private utilService: UtilService) {
  }

  ngOnInit() {
    this.loadNbRapportsToTreat();

    // event un rapport à été traité
    this.utilService.rapportTreat.subscribe(response => {
      this.rapportsToTreat = (this.rapportsToTreat - 1);
    });
  }

  loadNbRapportsToTreat() {
    this.rapportBusinessService.getNbRapportsToTreat()
    .subscribe(response => {
      this.rapportsToTreat = response;
    });
  }

}
