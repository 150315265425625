import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { LoginService } from 'src/app/shared/services/auth-services/login.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private router: Router,
              private snotifyService: SnotifyService,
              private loginService: LoginService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // intercept si different de l'url login
    if (request.url.search('/login') === -1) {

      this.checkTokenExpiration();

      // if (this.getToken() != undefined) {
      //   console.log('set token');

      //   request = request.clone({
      //     setHeaders: {
      //       'Authorization': `Basic ${this.getToken()}`,
      //       'X-Requested-With': 'XMLHttpRequest',
      //     }
      //   });
      //   return next.handle(request);
      // }
    }
    return next.handle(request);
  }


  // private methodes

  public getToken(): string {
    return sessionStorage.getItem('JWAUTOTOKEN');
  }

  setToken(login: string, pwd: string) {
    const expiration = moment(new Date()).add(30, 'minutes');
    sessionStorage.setItem('JWAUTOTOKEN', btoa(login + ':' + pwd + ':' + expiration));
  }

  checkTokenExpiration() {
    const token = this.getToken();
    const expiration = atob(token).split(':');
    const isTokenExpire = moment(moment(expiration[2], 'x')).isBefore(new Date());

    if (isTokenExpire) {// logout
      this.logout();
    } else { // extend token life
      this.setToken(expiration[0], expiration[1]);
    }
  }

  logout() {
    this.loginService.login = undefined;
    this.loginService.user = undefined;
    this.loginService.sendEvent();
    this.snotifyService.info('Votre session a expiré.', 'Déconnexion', {
      timeout: 3500,
      titleMaxLength: 30,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true
    });
  }

}
