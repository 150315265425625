import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { SnotifyService, ToastDefaults, SnotifyModule } from 'ng-snotify';

import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginService } from './shared/services/auth-services/login.service';
import { Authgard } from './shared/services/auth-services/authgard.service';
import { TokenInterceptorService } from './core/services/token-interceptor.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ChartModule } from 'primeng/chart';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    SnotifyModule,
    CoreModule,
    BrowserAnimationsModule,
    NgbModule,
    // ChartModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    TokenInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    SnotifyService,
    LoginService,
    Authgard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
