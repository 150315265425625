import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { HeaderComponent } from './layout/header/header.component';
import { SharedModule } from '../shared/shared.module';
import { MenuComponent } from './layout/menu/menu.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LoginComponent } from './layout/login/login.component';
import { FormsModule } from '@angular/forms';
import { LogoutComponent } from './layout/header/logout/logout/logout.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
  ],
  providers: [
  ],
  declarations: [
    HeaderComponent,
    MenuComponent,
    MenuComponent,
    FooterComponent,
    LoginComponent,
    LogoutComponent,
  ],
  exports: [
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    LoginComponent,
  ],
  entryComponents: [
    LogoutComponent,
  ],
})
export class CoreModule { }
