import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from './login.service';
import { RoleEnum } from '../../enum/RoleEnum';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {

  roleEnum = RoleEnum;

  constructor(private router: Router,
              private loginService: LoginService) {
  }

  canActivate() {
    if (this.loginService.user == undefined) {
      return false;
    }
    if (this.loginService.user.role === RoleEnum.ADMIN) {
      return true;
    } else {
      this.router.navigate(['/admin/calendrier']); // admin accueil
      return false;
    }
  }

}
