import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from 'src/app/model/page.model';
import { User } from 'src/app/model/user.model';
import { ChangePwd } from 'src/app/model/change-pwd.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private base_url = environment.backendUrl + 'api/v1/users';
  private logger = environment.logger;

  constructor(private http: HttpClient) {
  }

  /**
   * get la liste des users
   * @param param
   */
  getListUsers(param: string): Observable<Page> {
    const url = this.base_url + '/list' + param;
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.get<Page>(url, {headers});
  }

  getUserOrderByActif(param: string): Observable<Page> {
    const url = this.base_url + '/list/inactif' + param;
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.get<Page>(url, {headers});
  }


  /**
   * get la liste des users
   * @param param
   */
  searchListUsers(param: string, searchWord: String): Observable<Page> {
    const url = this.base_url + '/search/' + searchWord + param;
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.get<Page>(url, {headers});
  }

  /**
   * renvoi les informations d'un utilisateur
   * @param id 
   */
  getUserinfos(id: number) {
    const url = this.base_url + '/' + id;
    if (this.logger) {
      console.log(url);
      console.log('with param => ', id);
    }

    const headers = this.buildHeaders();
    return this.http.get<User>(url, {headers});
  }

  /**
   * create user
   * @param user
   */
  saveUser(user: User) {
    const url = this.base_url + '/create';
    if (this.logger) {
      console.log(url);
      console.log('with param => ', user);
    }

    const headers = this.buildHeaders();
    return this.http.post(url, user, {headers});
  }

  /**
   * edit user
   * @param user
   */
  editUser(user: User) {
    const url = this.base_url + '/edit';
    if (this.logger) {
      console.log(url);
      console.log('with param => ', user);
    }

    const headers = this.buildHeaders();
    return this.http.put(url, user, {headers});
  }

  /**
   * changePwdUser permet d'appeler le backend pour changer le mdp d'un user
   * @param changePwd
   */
  changePwdUser(changePwd: ChangePwd) {
    const url = this.base_url + '/update_pwd';
    if (this.logger) {
      console.log(url);
      console.log('with param => ', changePwd);
    }

    const headers = this.buildHeaders();
    return this.http.put(url, changePwd, {headers});
  }

  /**
   * delete user
   * @param id 
   */
  deleteUser(id: number) {
    const url = this.base_url + '/delete/' + id;
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.delete<Page>(url, {headers});
  }

  /**
   * reset le pwd d'un utilisateur
   * @param id 
   */
  resetUserPwd(id: number) {
    const url = this.base_url + '/reset_pwd';
    if (this.logger) {
      console.log(url);
      console.log('with param => ', id);
    }

    const headers = this.buildHeaders();
    return this.http.put<Page>(url, id, {headers});
  }

    /**
   * desactiveUser - désactive un user
   * @param id 
   */
  desactiveUser(id: number) {
    const url = this.base_url + '/desactive';
    if (this.logger) {
      console.log(url);
      console.log('with param => ', id);
    }

    const headers = this.buildHeaders();
    return this.http.put<Page>(url, id, {headers});
  }

  /**
   * buildHeaders for authentification
   */
  buildHeaders() {
    const token = sessionStorage.getItem('JWAUTOTOKEN');
    const tokenArray = atob(token).split(':');

    const _headers = new HttpHeaders();
    const headers = _headers.append('Authorization', 'Basic ' + btoa(tokenArray[0] + ':' + tokenArray[1]))
                            .append('X-Requested-With', 'XMLHttpRequest');

    return headers;
  }

}
