
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ConfirmePopupService } from './confirme-popup.service';

@Component({
  selector: 'app-confirme-popup',
  templateUrl: './confirme-popup.component.html',
  styleUrls: ['./confirme-popup.component.scss']
})
export class ConfirmePopupComponent implements OnInit, OnDestroy {

  @Input() message = 'Confirmation';
  @Input() confirmeTitle = 'Confirmer l\'action';
  @Input() yesButton = 'Oui';
  @Input() noButton = 'Annuler';

  responseValue: Subject<boolean> = new Subject<boolean>();

  constructor(public modalService: NgbModal,
              public activeModal: NgbActiveModal,
            private confirmePopupService: ConfirmePopupService) {
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.responseValue.unsubscribe();
  }

  sendConfirmToComponent() {
    this.confirmePopupService.sendConfirmToComponent(true);
    this.responseValue.next(true);
    this.activeModal.dismiss('Cross click');
  }

  sendCancelToComponent() {
    this.confirmePopupService.sendConfirmToComponent(false);
    this.responseValue.next(false);
    this.activeModal.dismiss('Cross click');
  }

}
