import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { LoginService } from 'src/app/shared/services/auth-services/login.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              public snotifyService: SnotifyService,
              public loginService: LoginService) { }

  ngOnInit() {

  }

  logout() {
    this.loginService.login = undefined;
    this.loginService.user = undefined;
    this.loginService.sendEvent();
    sessionStorage.removeItem('JWAUTOTOKEN');
    this.snotifyService.success('Vous avez bien été déconnecté.', 'Déconnexion', {
      timeout: 3500,
      titleMaxLength: 30,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true
    });
    this.activeModal.dismiss('Cross click');
  }

}
