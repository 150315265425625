<nav class="sidebar display_condition_tab">
  <div class="list-group">
      <div class="nested-menu">
          <li class="nested">
              <ul class="submenu">
                  <li>
                      <a routerLink="/admin/calendrier" routerLinkActive="active">
                        <i class="fa fa-home" aria-hidden="true"></i>Accueil
                      </a>
                  </li>
                  <li>
                      <a routerLink="/admin/proclamateurs" routerLinkActive="active">
                          <i class="fa fa-users" aria-hidden="true"></i> Proclamateurs
                      </a>
                  </li>
                  <li>
                      <a routerLink="/admin/rapports" routerLinkActive="active">
                          <i class="fa fa-file-text" aria-hidden="true"></i> Rapports <span *ngIf="rapportsToTreat != 0" class="badge badge-warning" title="Rapports à traiter">{{rapportsToTreat}}</span>
                      </a>
                  </li>
                  <!-- <li>
                      <a routerLink="/admin/anecdotes" routerLinkActive="active">
                          <i class="fa fa-comments" aria-hidden="true"></i> Anecdotes
                      </a>
                  </li> -->
                  <li>
                      <a routerLink="/admin/statistiques" routerLinkActive="active">
                          <i class="fa fa-pie-chart" aria-hidden="true"></i> Statistiques
                      </a>
                  </li>
                  <li>
                      <a routerLink="/admin/parametres" routerLinkActive="active">
                          <i class="fa fa-wrench" aria-hidden="true"></i> Paramètres
                      </a>
                  </li>
              </ul>
          </li>
      </div>
  </div>
  <app-footer></app-footer>
</nav>