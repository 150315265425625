import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Authgard } from './shared/services/auth-services/authgard.service';
import { AdminGuardService } from './shared/services/auth-services/admin-guard.service';
import { UserGuardService } from './shared/services/auth-services/user-guard.service';
import { LoginComponent } from './core/layout/login/login.component';

const routes: Routes = [
  {
    path : 'login',
    component : LoginComponent,
  },
  {
    path : 'admin',
    loadChildren : () => import('./modules/admin/accueil/accueil.module').then(m => m.AccueilModule),
    canActivate: [Authgard, AdminGuardService]
  },
  {
    path : 'admin',
    loadChildren : () => import('./modules/admin/proclamateurs/proclamateurs.module').then(m => m.ProclamateursModule),
    canActivate: [Authgard, AdminGuardService]
  },
  {
    path : 'admin',
    loadChildren : () => import('./modules/admin/anecdotes/anecdotes.module').then(m => m.AnecdotesModule),
    canActivate: [Authgard, AdminGuardService]
  },
  {
    path : 'admin',
    loadChildren : () => import('./modules/admin/statistiques/statistiques.module').then(m => m.StatistiquesModule),
    canActivate: [Authgard, AdminGuardService]
  },
  {
    path : 'admin',
    loadChildren : () => import('./modules/admin/parametres/parametres.module').then(m => m.ParametresModule),
    canActivate: [Authgard, AdminGuardService]
  },
  {
    path : 'admin',
    loadChildren : () => import('./modules/admin/rapports/rapports.module').then(m => m.RapportsModule),
    canActivate: [Authgard, AdminGuardService]
  },
  {
    path : '',
    redirectTo: 'calendrier',
    pathMatch: 'full'
  },
  {
    path : 'calendrier',
    loadChildren : () => import('./modules/calendrier/calendrier.module').then(m => m.CalendrierModule),
    canActivate: [Authgard, UserGuardService]
  },
  {
    path : 'instructions',
    loadChildren : () => import('./modules/instructions/instructions.module').then(m => m.InstructionsModule),
    canActivate: [Authgard]
  },
  {
    path : 'parcours',
    loadChildren : () => import('./modules/parcours/parcours.module').then(m => m.ParcoursModule),
    canActivate: [Authgard]
  },
  {
    path : 'rapport',
    loadChildren : () => import('./modules/rapport/rapport.module').then(m => m.RapportModule),
    canActivate: [Authgard]
  },
  {
    path : 'profil',
    loadChildren : () => import('./modules/profil/profil.module').then(m => m.ProfilModule),
    canActivate: [Authgard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
