import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  rapportTreat: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  sendRapportTreatNotifToComponent(response: boolean) {
    this.rapportTreat.next(response);
  }
}
