import { Component, OnInit } from '@angular/core';
import { LoginService } from './shared/services/auth-services/login.service';
import * as moment from 'moment';
import { User } from './model/user.model';
import { Login } from './model/login';
import { SnotifyService } from 'ng-snotify';
import { RoleEnum } from './shared/enum/RoleEnum';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Predication Autoroute';

  loged = false;
  user: User;
  roleEnum = RoleEnum;

  constructor(private router: Router,
              private loginService: LoginService,
              private snotifyService: SnotifyService) { }

  ngOnInit() {
    this.checkToken();
    this.loginService.getChange().subscribe(event => {
      if (this.loginService.user != undefined) {
        this.user = this.loginService.user;
        this.loged = true;
        if (this.loginService.user.role === RoleEnum.USER) {
          this.router.navigate(['/calendrier']); // user accueil
        }
        if (this.loginService.user.role === RoleEnum.ADMIN) {
          this.router.navigate(['/admin/calendrier']); // admin accueil
        }
      } else {
        this.loged = false;
      }
    });
  }

  checkToken() {
    const token = this.getToken();
    const tokenArray = atob(token).split(':');
    const isTokenExpire = moment(moment(tokenArray[2], 'x')).isBefore(new Date());

    if (token) {
      if (isTokenExpire) { // logout and remove token
        sessionStorage.removeItem('JWAUTOTOKEN');
        this.logout();
      } else { // on se reconnect
        const login = new Login(tokenArray[0], tokenArray[1]);
        this.loginService.loginSubmit(login).subscribe(response => {
          this.setToken(response, login);
        }, error => {
          this.snotifyService.error('Erreur lors de la connexion', 'Connexion', {
            timeout: 3500,
            titleMaxLength: 30,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true
          });
      });
      }
    }
  }

  getToken(): string {
    return sessionStorage.getItem('JWAUTOTOKEN');
  }

  setToken(user: User, login: Login) {
    const expiration = moment(new Date()).add(30, 'minutes');

    sessionStorage.setItem('JWAUTOTOKEN', btoa(login.user + ':' + login.pwd + ':' + expiration));
    this.loginService.user = user;
    this.loginService.sendEvent();
  }

  logout() {
    this.loginService.login = undefined;
    this.loginService.user = undefined;
    this.loginService.sendEvent();
    this.snotifyService.info('Votre session a expiré.', 'Déconnexion', {
      timeout: 3500,
      titleMaxLength: 30,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true
    });
  }

}
