<ng-container *ngIf="loged">
  <app-header></app-header>
  
  <ng-container *ngIf="loged && user.role == roleEnum.ADMIN">
    <app-menu></app-menu>
  </ng-container>

  <section class="main-container">
    <router-outlet></router-outlet>
  </section>
</ng-container>



<ng-container *ngIf="!loged">
  <app-login></app-login>
</ng-container>

<ng-snotify></ng-snotify>