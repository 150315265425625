import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Evenement } from 'src/app/model/evenement.model';
import { EvenementDTO } from 'src/app/model/evenementDTO.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private base_url = environment.backendUrl + 'api/v1/event';
  private logger = environment.logger;

  constructor(private http: HttpClient) { }

    /**
   * get liste des évenements d'un utilisateur
   * @param param if of the user to get list of events
   */
  getAllEventByUser(id: number): Observable<Array<EvenementDTO>> {
    const url = this.base_url + '/list/' + id;
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.get<Array<EvenementDTO>>(url, {headers});
  }

  /**
   * getEventRapportNoSentById get all rapport from user where the report was not sent
   * @param id 
   */
  getEventRapportNoSentById(id: number): Observable<Array<Evenement>> {
    const url = this.base_url + '/list/' + id + '/no_rapport';
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.get<Array<Evenement>>(url, {headers});
  }

  getAllEvent(): Observable<Array<EvenementDTO>> {
    const url = this.base_url + '/list/all';
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.get<Array<EvenementDTO>>(url, {headers});
  }

  getEventById(id: number): Observable<Evenement> {
    const url = this.base_url + '/' + id;
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.get<Evenement>(url, {headers});
  }

  getListEventOnSpecificDate(id: number | string): Observable<Evenement> {
    const url = this.base_url + '/list/date/' + id;
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.get<Evenement>(url, {headers});
  }


  /**
   * create event
   * @param event the event to create
   */
  createEvent(event: Evenement) {
    const url = this.base_url + '/create';
    if (this.logger) {
      console.log(url);
      console.log('with param => ', event);
    }

    const headers = this.buildHeaders();
    return this.http.post(url, event, {headers});
  }

  /**
   * validateEvent envoie la validation d'un évement au backend
   * @param id l'evenement a valider
   */
  validateEvent(id: Number) {
    const url = this.base_url + '/validate/' + id;
    if (this.logger) {
      console.log(url);
      console.log('with param => ', id);
    }

    const headers = this.buildHeaders();
    return this.http.put(url, undefined, {headers});
  }

    /**
   * validateEventList envoie la validation d'un ou plusieurs évenements au backend
   * @param ids la liste d'evenement a valider
   */
    validateEventList(ids: Array<Number>) {
      const url = this.base_url + '/validateList';
      if (this.logger) {
        console.log(url);
        console.log('with param => ', ids);
      }
  
      const headers = this.buildHeaders();
      return this.http.post(url, ids, {headers});
    }

    /**
   * rejectEventList envoie la validation d'un évenement au backend
   * @param ids la liste d'evenement a refuser
   */
    rejectEventList(ids: Array<Number>) {
      const url = this.base_url + '/rejectEventList';
      if (this.logger) {
        console.log(url);
        console.log('with param => ', ids);
      }
  
      const headers = this.buildHeaders();
      return this.http.post(url, ids, {headers});
    }

  /**
   * rejectEvent: envoie le refus d'un évement au back end
   * @param id l'evenement a refuser
   */
  rejectEvent(id: Number) {
    const url = this.base_url + '/reject/' + id;
    if (this.logger) {
      console.log(url);
      console.log('with param => ', id);
    }

    const headers = this.buildHeaders();
    return this.http.put(url, undefined, {headers});
  }

  /**
   * deleteEventById
   * @param id id to the event to delete
   */
  deleteEventById(id: Number) {
    const url = this.base_url + '/delete/' + id;
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.delete(url, {headers});
  }


  /**
   * buildHeaders for authentification
   */
  buildHeaders() {
    const token = sessionStorage.getItem('JWAUTOTOKEN');
    const tokenArray = atob(token).split(':');

    const _headers = new HttpHeaders();
    const headers = _headers.append('Authorization', 'Basic ' + btoa(tokenArray[0] + ':' + tokenArray[1]))
                            .append('X-Requested-With', 'XMLHttpRequest');

    return headers;
  }

}
