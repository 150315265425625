<div class="modal-header">
  <h4 class="modal-title">Déconnexion</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <p>
      Vous allez être déconnecté de l'application. Êtes-vous sûr de vouloir continuer?
    </p>
  </div>
  <!-- Btn confirmation -->
  <div class="row" id="confirme_btn">
    <div class="col-md-6">
        <button type="button" class="btn btn-danger btn-block" (click)="activeModal.dismiss('Cross click')"><i class="fa fa-times" aria-hidden="true"></i> Annuler</button>
    </div>    
    <div class="col-md-6">
        <button type="button" class="btn btn-success btn-block" (click)="logout()"><i class="fa fa-check" aria-hidden="true"></i> Oui</button>
    </div>
  </div>
</div>
