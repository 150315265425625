import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmePopupComponent } from './confirme-popup/confirme-popup.component';
import { ConfirmePopupService } from './confirme-popup/confirme-popup.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [
    ConfirmePopupComponent,
  ],
  exports: [
    ConfirmePopupComponent,
  ],
  providers: [
    NgbActiveModal,
    ConfirmePopupService,
  ]
})
export class PopupModule { }
