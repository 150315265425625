<div class="navbar">
    <a class="navbar-brand hand" routerLink="/calendrier">
        <img id="img_brand" src="assets/images/jw.png" height="50px" class="img-fluid"/>
    </a>
    <!-- format ordinateur menu user -->
    <!-- <div id="clock" class="display_condition display_condition_tab">{{now | moment:'DD/MM/YYYY'}} {{now | moment:'HH:mm:ss'}}</div> -->
    <h3 id="title" class="ml-10 display_condition display_condition_tab">Prédication sur les aires d'autoroute <span id="version_id">v {{version}}</span></h3>
    <ng-container *ngIf="user.role == roleEnum.USER">
        <a class="navbar-brand hand bnt-link link display_condition" routerLink="/calendrier" routerLinkActive="active"><i class="fa fa-home" aria-hidden="true"></i> Accueil</a>
    </ng-container>

    <!-- format tel / tablettes menu admin -->
    <i class="fa fa-bars hand menu_tel_admin" *ngIf="user.role == roleEnum.ADMIN" (click)="toggleTelMenu()" aria-hidden="true"></i>
    <div class="dropdown_menu_admin" *ngIf="printMenu && user.role == roleEnum.ADMIN">
        <a class="hand bnt-link" routerLink="/admin/calendrier" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-home" aria-hidden="true"></i> Accueil</a>
        <a class="hand bnt-link" routerLink="/profil" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-user user_info_box"></i> Profil</a>
        <a class="hand bnt-link" routerLink="/admin/proclamateurs" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-users" aria-hidden="true"></i> Proclamateurs</a>
        <a class="hand bnt-link" routerLink="/admin/rapports" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-file" aria-hidden="true"></i> Rapports</a>
        <a class="hand bnt-link" routerLink="/rapport" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-file" aria-hidden="true"></i> Mes rapports</a>
        <a class="hand bnt-link" routerLink="/instructions" routerLinkActive="active" (click)="toggleTelMenu()">Instructions <i class="fa fa-exclamation-triangle" id="instructions_warn" aria-hidden="true"></i></a>
        <a class="hand bnt-link" routerLink="/parcours" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-map" aria-hidden="true"></i> Parcours</a>
        <!-- <a class="hand bnt-link" routerLink="/admin/anecdotes" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-comments" aria-hidden="true"></i> Anecdotes</a> -->
        <a class="hand bnt-link" routerLink="/admin/statistiques" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-pie-chart" aria-hidden="true"></i> Statistiques</a>
        <a class="hand bnt-link" routerLink="/admin/parametres" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-wrench"></i> Paramètres</a>
        <a class="hand bnt-link" (click)="logout();toggleTelMenu()"><i class="fa fa-lock hand" aria-hidden="true"></i> Déconnexion</a>
    </div>

    <!-- format tel / tablettes menu user -->
    <i class="fa fa-bars hand menu_tel" *ngIf="user.role == roleEnum.USER" (click)="toggleTelMenu()" aria-hidden="true"></i>
    <div class="dropdown_menu" *ngIf="printMenu && user.role == roleEnum.USER">
        <a class="hand bnt-link" routerLink="/calendrier" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-home" aria-hidden="true"></i> Accueil</a>
        <a class="hand bnt-link" routerLink="/instructions" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-exclamation-triangle" id="instructions_warn" aria-hidden="true"></i> Instructions</a>
        <a class="hand bnt-link" routerLink="/parcours" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-map" aria-hidden="true"></i> Parcours</a>
        <a class="hand bnt-link" routerLink="/rapport" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-file" aria-hidden="true"></i> Rapports</a>
        <a class="hand bnt-link" routerLink="/profil" routerLinkActive="active" (click)="toggleTelMenu()"><i class="fa fa-user user_info_box"></i> Profil</a>
        <a class="hand bnt-link" (click)="logout();toggleTelMenu()"><i class="fa fa-lock hand" aria-hidden="true"></i> Déconnexion</a>
    </div>

    <a class="float-right display_condition" routerLink="/profil" routerLinkActive="active"><i class="fa fa-user"></i> {{user?.prenom}} {{user?.nom}}</a>
    <a class="hand bnt-link" routerLink="/instructions" routerLinkActive="active"><i class="fa fa-exclamation-triangle" id="instructions_warn" aria-hidden="true"></i> Instructions</a>
    <a class="hand bnt-link" routerLink="/parcours" routerLinkActive="active"><i class="fa fa-map" aria-hidden="true"></i> Parcours</a>
    <a class="hand bnt-link" routerLink="/rapport" routerLinkActive="active"><i class="fa fa-file" aria-hidden="true"></i> Mes rapports</a>
    <a class="float-right display_condition"><i class="fa fa-lock hand" (click)="logout()" aria-hidden="true"></i></a>

</div>