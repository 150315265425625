<div class="modal-header">
  <h4 class="modal-title">{{confirmeTitle}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
      <div style="text-align: center">
          <!-- <i class="fa fa-exclamation-triangle" id="WarnIcon" aria-hidden="true"></i> -->
      </div>
      <div id="confirmeMessage" class="col-md-12" *ngIf="message">
          {{message}}
      </div>
  </div>
  <div class="d-flex justify-content-around mt-3">
      <button type="button" id="yesButton" class="btn btn-success" (click)="sendConfirmToComponent()">
              <i class="fa fa-check"></i>
              {{yesButton}}
      </button>
      <button type="button" class="btn btn-danger" (click)="sendCancelToComponent()">
              <i class="fa fa-times"></i>
              {{noButton}}
      </button>
  </div>
</div>
