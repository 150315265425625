import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Login } from 'src/app/model/login';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/model/user.model';
import { environment } from 'src/environments/environment';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  login: Login;
  user: User;
  change: EventEmitter<Login> = new EventEmitter<Login>();

  private base_url = environment.backendUrl + 'login';

  constructor(private http: HttpClient) { }

  sendEvent() {
    this.change.emit(this.login);
  }

  /**
   * send event to calendar component print new event added
   */
  @Output()
  getChange(): Observable<Login> {
    return this.change;
  }


  loginSubmit(login: Login) {
    const _headers = new HttpHeaders();
    const headers = _headers.append('Authorization', 'Basic ' + btoa(login.user + ':' + login.pwd))
                            .append('X-Requested-With', 'XMLHttpRequest');

    return this.http.post<User>(this.base_url, undefined, {headers});
  }

}
