
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ConfirmePopupService {

    responseValue: Subject<boolean> = new Subject<boolean>();

    constructor() { }

    sendConfirmToComponent(response: boolean) {
        this.responseValue.next(response);
    }

}
