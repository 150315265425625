import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutComponent } from './logout/logout/logout.component';
import { LoginService } from 'src/app/shared/services/auth-services/login.service';
import { User } from 'src/app/model/user.model';
import { RoleEnum } from 'src/app/shared/enum/RoleEnum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  version = environment.version;
  printLoading: boolean;
  now: Date = new Date();
  user: User = new User();
  roleEnum = RoleEnum;
  printMenu = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private modalService: NgbModal) {

    setInterval(() => {
      this.now = new Date();
    }, 1);
  }

  ngOnInit() {
    this.user = this.loginService.user;
  }

  logout() {
    const modalRef = this.modalService.open(LogoutComponent);
  }

  toggleTelMenu() {
    this.printMenu = !this.printMenu;
  }

}

