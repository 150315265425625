import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Rapport } from 'src/app/model/rapport.model';
import { Page } from 'src/app/model/page.model';

@Injectable({
  providedIn: 'root'
})
export class RapportService {

  private base_url = environment.backendUrl + 'api/v1/rapport';
  private logger = environment.logger;

  constructor(private http: HttpClient) { }


  /**
   * getAllRapportByUser : récupére les rapports d'un user
   * @param id 
   */
  getAllRapportByUser(param, id: number): Observable<Page> {
    const url = this.base_url + '/list/' + id + param;
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.get<Page>(url, {headers});
  }

  /**
   * getAllRapport : récupére tous les rapports de tous les users
   */
  getAllRapports(param) {
    const url = this.base_url + '/list/all' + param;
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.get<Page>(url, {headers});
  }

  /**
   * deleteRapport
   * @param id 
   */
  deleteRapport(id: Number) {
    const url = this.base_url + '/delete/' + id;
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.delete<Page>(url, {headers});
  }

  /**
   * traiterRapport marque le rapport comme traité
   * @param id 
   */
  traiterRapport(rapport: Rapport) {
    const url = this.base_url + '/traiter';
    if (this.logger) {
      console.log(url);
    }

    const headers = this.buildHeaders();
    return this.http.put<Page>(url, rapport, {headers});
  }

  createRapport(rapport: Rapport) {
    const url = this.base_url + '/create';
    if (this.logger) {
      console.log(url);
      console.log('with param => ', rapport);
    }

    const headers = this.buildHeaders();
    return this.http.post(url, rapport, {headers});
  }

    /**
   * getNbRapportsToTreat : récupére le nb de rapports à traiter
   */
     getNbRapportsToTreat(): Observable<Number> {
      const url = this.base_url + '/to_treat';
      if (this.logger) {
        console.log(url);
      }

      const headers = this.buildHeaders();
      return this.http.get<Number>(url, {headers});
    }

  /**
   * buildHeaders for authentification
   */
  buildHeaders() {
    const token = sessionStorage.getItem('JWAUTOTOKEN');
    const tokenArray = atob(token).split(':');

    const _headers = new HttpHeaders();
    const headers = _headers.append('Authorization', 'Basic ' + btoa(tokenArray[0] + ':' + tokenArray[1]))
                            .append('X-Requested-With', 'XMLHttpRequest');

    return headers;
  }

}
