import { Assemble } from './assemble.model';
import { RoleEnum } from '../shared/enum/RoleEnum';

export class User {

    constructor(public id ?: number,
                public email ?: string,
                public password ?: string,
                public nom ?: string,
                public prenom ?: string,
                public telephone_portable ?: string,
                public telephone_fix ?: string,
                public adresse ?: string,
                public actif ?: boolean,
                public visible ?: boolean,
                public assemble ?: Assemble,
                public role ?: RoleEnum) {
        this.actif = false;
        this.visible = true;
    }

}
