import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentPipe } from './pipes/moment.pipe';
import { UsersService } from './services/users.service';
import { PopupModule } from './popup/popup.module';
import { ConfirmePopupComponent } from './popup/confirme-popup/confirme-popup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from './services/event.service';
import { RecaptchaModule } from 'ng-recaptcha';


@NgModule({
  imports: [
    CommonModule,
    PopupModule,
    RecaptchaModule,
    NgbModule,
  ],
  declarations: [
    MomentPipe,
  ],
  entryComponents: [
    ConfirmePopupComponent,
  ],
  exports: [
    MomentPipe,
    RecaptchaModule,
  ],
  providers: [
    MomentPipe,
    UsersService,
    EventService,
  ]
})
export class SharedModule { }
