<div id="login_comp">
	<div class="d-flex justify-content-center h-100">
		<div class="card">
			<div class="card-header center">
				<img id="img_brand" src="assets/images/jw.png" class="img-fluid"/>
				<h3>Prédication sur les aires d'autoroutes - Rousset</h3>
			</div>
			<div class="card-body">
				<div class="input-group form-group">
					<div class="input-group-prepend">
						<span class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i></span>
					</div>
					<input type="text" [(ngModel)]="login.user" class="form-control" placeholder="login">
				</div>
				<div class="input-group form-group">
					<div class="input-group-prepend">
						<span class="input-group-text"><i class="fa fa-lock" aria-hidden="true"></i></span>
					</div>
					<input type="password" [(ngModel)]="login.pwd" class="form-control" placeholder="mot de  passe">
				</div>
				<div class="input-group form-group center">
					<re-captcha (resolved)="resolved($event)" siteKey="6LeFDbwUAAAAAM7xOLpPU57eHJdnyqKt6Z4cAuhz"></re-captcha>
				</div>
				<div class="input-group form-group">
					<button type="button" [disabled]="robotDisabled" (click)="submit()" class="btn btn-block login_btn">Se connecter</button>
				</div>
			</div>
		</div>
	</div>
</div>