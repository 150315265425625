import { Component, OnInit } from '@angular/core';
import { Login } from 'src/app/model/login';
import { LoginService } from 'src/app/shared/services/auth-services/login.service';
import { SnotifyService } from 'ng-snotify';
import { User } from 'src/app/model/user.model';
import * as moment from 'moment';
import { SnotifyPosition } from 'ng-snotify';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  login: Login = new Login();
  robotDisabled = true;

  constructor(private snotifyService: SnotifyService,
              private loginService: LoginService) { }

  ngOnInit() {
  }

  submit() {
    this.loginService.loginSubmit(this.login).subscribe(response => {
        this.setToken(response);
        this.snotifyService.info('Bienvenue ' + response.prenom, 'Connexion', {
          timeout: 3500,
          titleMaxLength: 30,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: SnotifyPosition.rightTop
        });
      }, error => {
        this.snotifyService.error('Erreur lors de la connexion', 'Connexion', {
          timeout: 3500,
          titleMaxLength: 30,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true
        });
    });
  }

  resolved(captchaResponse: string) {
    this.robotDisabled = false;
  }

  setToken(user: User) {
    const expiration = moment(new Date()).add(30, 'minutes');

    sessionStorage.setItem('JWAUTOTOKEN', btoa(this.login.user + ':' + this.login.pwd + ':' + expiration));
    this.loginService.user = user;
    this.loginService.sendEvent();
  }

}
