import { Injectable } from '@angular/core';
import { RapportService } from './rapport.service';
import { Rapport } from 'src/app/model/rapport.model';

@Injectable({
  providedIn: 'root'
})
export class RapportBusinessService {

  constructor(private httpService: RapportService) { }


  getAllRapportByUser(page: number, size: number, sort: string, id: number) {
    const param = this.buildPaginationParam(page, size, sort);

    return this.httpService.getAllRapportByUser(param, id);
  }

  getAllRapports(page: number, size: number, sort: string) {
    const param = this.buildPaginationParam(page, size, sort);

    return this.httpService.getAllRapports(param);
  }

  deleteRapport(id: Number) {
    return this.httpService.deleteRapport(id);
  }

  traiterRapport(rapport: Rapport) {
    return this.httpService.traiterRapport(rapport);
  }

  createRapport(rapport: Rapport) {
    return this.httpService.createRapport(rapport);
  }

  getNbRapportsToTreat() {
    return this.httpService.getNbRapportsToTreat();
  }

  buildPaginationParam (page: number, size: number, sort: string): string {
    let param = '';
    if (page != undefined) {
      param = param + '?page=' + page;
    }
    if (size != undefined) {
      param = param + '&size=' + size + '';
    }

    return param;
  }

}
