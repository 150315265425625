import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LoginService } from './login.service';
import { RoleEnum } from '../../enum/RoleEnum';

@Injectable({
  providedIn: 'root'
})
export class UserGuardService implements CanActivate {

  roleEnum = RoleEnum;

  constructor(private router: Router,
    private loginService: LoginService) {
  }

  canActivate() {
      if (this.loginService.user != undefined && this.loginService.user.role === RoleEnum.USER) {
        return true;
      } else {
        this.router.navigate(['/admin/calendrier']); // Admin accueil
        return false;
      }
  }

}
